import React, { Component } from 'react';
import './App.css';

import Autocomplete from 'react-autocomplete';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

const sender = require('./lib/sender');

class SearchPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      data: undefined,
      waitSign: false,
      searchValue: '',
      searchItems: [],
    };
  }

  componentDidMount() {
    const {name} = this.props.match ? this.props.match.params : {};
    if(!name) return;
    this.setState({searchValue: name});
    this.search(name);
  }

  async search(value) {
    const search = typeof value === 'string' ? value : this.state.searchValue;

    this.props.history.push(`/search/${search}`)

    this.setState({ data: undefined, waitSign: true });

    const data = await sender(`search/${search}`);

    this.setState({ data, waitSign: false });
  }

  async setData(searchValue) {
    this.setState({searchValue})
    const body = await sender(`keywords/${searchValue}`);
    if (body && Array.isArray(body)) this.setState({searchItems: body.slice(0, 20)})
  }

  render() {
    const hasName = !!this.props.match;
    const {name} = hasName ? this.props.match.params : {};
    let resultIcons;
    let error = this.state.data && this.state.data.error;
    if (this.state.data) {
      const { data } = this.state;
      resultIcons = data;
    }

    const { path } = this.props.match;
    const isSearch = ["/", "/search"].includes(path);
    const searchClass = isSearch ? "fs" : "fs_small";
    const calculateformClass = isSearch ? "calculateform" : "calculateform calculateform_small";

    return(
    <>
		<section className={"section grd1 " + searchClass}>
			<div className="cl"></div>
			<div className="container">
				<div className="row">	
					<div className="col-md-10 col-md-offset-1 col-sm-12 text-center">
            { isSearch && <>
              <h1>Icons for React Native</h1>
              <p className="lead">Use more than 20.000 icons in your React Native application!</p>
		<br /><a href="/packs">React Native Icon Packs</a>
            </>}
						<form onSubmit={this.search.bind(this)} className={calculateformClass}>
						    <div className="item-box">
						        <div className="item-top form-inline">
						            <div className="form-group">
						                <div className="input-group2">
						                    <span className="input-addon">
												<i className="fa fa-link"></i>
											</span>

                <Autocomplete
                inputProps={{className: "form-control"}}
                wrapperStyle={{ position: 'relative' }}
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 0',
                  fontSize: '90%',
                  color: '#000',
                  textAlign: 'left',
                  position: 'fixed',
                  // position: 'absolute', top: '35px', left: 0,
                  overflow: 'auto',
                  maxHeight: '50%',
                  zIndex: 70,
                }}
                getItemValue={(item) => item.name}
                items={this.state.searchItems}
                renderItem={(item, isHighlighted) =>
                  <div key={item.name+Math.random()} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item.name}
                  </div>
                }
                value={this.state.searchValue}
                onChange={({target: {value}}) => this.setData(value)}
                onSelect={searchValue => {
                  this.setState({searchValue});
                  this.search(searchValue);
                }}
                />

						                </div>
						            </div>
						            <input name="send" onClick={this.search.bind(this)} placeholder="Search" defaultValue="Search" className="btn btn-default" />
						        </div>
						    </div>
						</form>
					</div>
				</div>
			</div>

          { this.state.waitSign && <div>
              <div>SPIN</div>
              searching...
            </div> }
		</section>

{ resultIcons && !error &&
<section className="section bt">
<div className="container">
  <div className="section-title text-center">
    <h3>{this.state.searchValue}</h3>
    <hr />
    <p className="lead">{resultIcons.length} icons</p>
  </div>

  <div className="row clientv2">
    {resultIcons.map( ({group, name}, i) =>
        <div className="col-md-3 col-sm-6 col-xs-12" key={i}>
          <div className="client-box">
            <Link to={`/icon/${group}/${name}`}><img width="80" className="img-responsive" src={`/static/${ group }/${ name }.png`} /></Link>
            <p className="client-box-text"><Link to={`/icon/${group}/${name}`}>{name}</Link></p>
          </div>
        </div>
    )}
  </div>

          <hr className="invis" />

  <div className="row callout bgcolor">
    <div className="col-md-9">
      <p className="lead">Can't find? We'll help you!</p>
    </div>
    <div className="col-md-3">
      <div className="button-wrap text-center">
        <a href="/contacts" className="btn btn-transparent btn-light btn-lg">Contact us</a>
      </div>
    </div>
  </div>
</div>
</section>
}

    </>
    )
  }
}

class GroupsPage extends Component {
  state = {
    groups: [],
    groupsData: {},
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const groupsData = await sender('groups');
    this.setState({groups: Object.keys(groupsData), groupsData});
  }

  render() {
    const {groups, groupsData} = this.state;
    return(
<>
        <SearchPage {...this.props} />

<section className="section bt">
<div className="container">
  <div className="section-title text-center">
    <small>react-native-ico</small>
    <h3>Icon Packs</h3>
    <hr />
    <p className="lead">Explore our extensive library of Free React Native icons, designed to enhance the visual aesthetics and functionality of your mobile applications.</p>
  </div>

  <div className="row">

  {groups && groups.map( (item, i) =>
      <div className="col-md-6 col-sm-6" key={i}>
        <div className="ebook-details copybox row">
          <div className="col-md-4">
            <Link to={`/pack/${item}`}><img src={`/static/${ item }/${ groupsData[item].icon }.png`} alt="" className="img-pack" /></Link>
          </div>
          <div className="mt-2 col-md-8">
            <div className="book-details">
              <small>Premium Pack</small>
              <Link to={`/pack/${item}`}><h3>{item}</h3></Link>

              <p>{groupsData[item].count} Icons</p>
              <Link to={`/pack/${item}`} className="btn btn-transparent">View All</Link>
            </div>
          </div>
        </div>
      </div>
    )}

  </div>
</div>
</section>
</>
    )
  }
}

class GroupPage extends Component {
  state = {
    group: '',
    icons: [],
  }

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { group } = this.props.match.params;
    const icons = await sender(`groups/${group}`);
    this.setState({group, icons});
  }

  render() {
    const {group, icons} = this.state;
    return(
      <>
        <SearchPage {...this.props} />

<section className="section bt">
<div className="container">
  <div className="section-title text-center">
    <h3>{group}</h3>
    <p className="lead">npm install --save react-native-ico-{group} react-native-svg</p>
    <hr />
    <p className="lead">{icons.length} icons</p>
  </div>

  <div className="row clientv2">
    {icons && icons.map( (name, i) =>
        <div className="col-xl-1 col-lg-2 col-md-3 col-sm-4 col-xs-6" key={i}>
          <div className="client-box">
            <Link to={`/icon/${group}/${name}`}><img width="50" className="img-responsive" src={`/static/${ group }/${ name }.png`} /></Link>
            <p className="client-box-text"><Link to={`/icon/${group}/${name}`}>{name}</Link></p>
          </div>
        </div>
    )}
  </div>

          <hr className="invis" />

  <div className="row callout bgcolor">
    <div className="col-md-9">
      <p className="lead">Can't find? We'll help you!</p>
    </div>
    <div className="col-md-3">
      <div className="button-wrap text-center">
        <a href="/contacts" className="btn btn-transparent btn-light btn-lg">Contact us</a>
      </div>
    </div>
  </div>
</div>
</section>

      </>
    )
  }
}

class IconPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {group, icon} = this.props.match.params;
    return(
<>
        <SearchPage {...this.props} />


<section className="section lb">
<div className="container">
  <div className="section-title text-center">
    <a href={ '/pack/' + group }><small>react-native-ico-{group}</small></a>
      <h3>{icon}</h3>
    <hr />
  </div>

  <div className="row">
    <div className="col-md-6 col-sm-12">
      <div className="feature-list">
        <img width="100%" src={`/static/${ group }/${ icon }.png`} />
        <ul>
        <li>50%<br />
        <img width="50%" src={`/static/${ group }/${ icon }.png`} />
        </li>
        <li>15%<br />
        <img width="15%" src={`/static/${ group }/${ icon }.png`} />
        </li>
        <li>5%<br />
        <img width="5%" src={`/static/${ group }/${ icon }.png`} />
        </li>
        </ul>
      </div>
    </div>

    <div className="col-md-6 col-sm-12">
      <div className="feature-list">
        <h4>Usage</h4>
        <hr />

        <ul>

        <li>Using with react-native-ico-{group}<br />
        <SyntaxHighlighter language="javascript" style={docco} showLineNumbers={true} wrapLines={true}>
          {`import Icon from 'react-native-ico-${group}';\n//...\n<Icon name="${icon}" />`}
        </SyntaxHighlighter>
        </li>

        </ul>

        <a href="/usage" className="btn btn-transparent">View how to use</a>
      </div>
    </div>
  </div>
</div>

</section>

</>
    )
  }
}

class UsagePage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return(
<>
        <SearchPage {...this.props} />


<section className="section lb">
<div className="container">
  <div className="section-title text-center">
    <small>How to...</small>
      <h3>Usage</h3>
    <hr />
  </div>

  <div className="row">
    <div className="col-md-6 col-sm-12">
      <div className="feature-list">

        <ul>
        <li>Install<br />
        <SyntaxHighlighter language="bash" style={docco} showLineNumbers={true} wrapLines={true}>
          {`npm install --save react-native-ico react-native-svg`}
        </SyntaxHighlighter>
        </li>

        <li>Link react-native-svg<br />
        <SyntaxHighlighter language="bash" style={docco} showLineNumbers={true} wrapLines={true}>
          {`react-native link react-native-svg`}
        </SyntaxHighlighter>
        </li>

        <li>pod install ( for iOS )<br />
        <SyntaxHighlighter language="bash" style={docco} showLineNumbers={true} wrapLines={true}>
          {`cd ios && pod install && cd ..`}
        </SyntaxHighlighter>
        </li>

        <li>Usage<br />
        <SyntaxHighlighter language="javascript" style={docco} showLineNumbers={true} wrapLines={true}>
          {`import Icon from 'react-native-ico';\n//...\n<Icon name="home" group="font-awesome" />`}
        </SyntaxHighlighter>
        </li>

        </ul>

        <a href="/usage" className="btn btn-transparent">View how to use</a>
      </div>
    </div>
  </div>
</div>

</section>

</>
    )
  }
}

class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
<>
      <Router>
        <Switch>
          <Route path="/search/:name" render={(props) => <SearchPage {...props} />} />
          <Route path="/search" render={(props) => <SearchPage {...props} />} />
          <Route path="/packs" render={(props) => <GroupsPage {...props} />} />
          <Route path="/pack/:group" render={(props) => <GroupPage {...props} />} />
          <Route path="/icon/:group/:icon" render={(props) => <IconPage {...props} />} />
          <Route path="/usage" render={(props) => <UsagePage {...props} />} />
          <Route path="/" render={(props) => <SearchPage {...props} />} />
        </Switch>
      </Router>
</>
    );
  }
}

export default App;

const request = require('request');
const defaultUrl = 'https://api.ico.simpleness.org';

/**
 * Send data to backend server
 * @param {object} - request parameters:
 *  - method {string} - send method, default: POST
 *  - link {string} - backend API link, default: ''
 *  - json {object} - json data to send, default: {}
 * @param {function} next - request's callback function
 * @example: sender( { json: { text: 'search data' } }, () => {} ) - POST / with json data
 * @example: sender( { method: 'GET', link: 'cities' }, () => {} ) - GET /cities with no data
 **/
module.exports = exports = (params) => new Promise( (resolve, reject) => {
  if(typeof params === 'string') params = {link: params};
  let { method = 'GET', link = '', json = {} } = params;
  if (!link.match(/^\//)) link = '/' + link;

  const options = {
    uri: defaultUrl + link,
    method,
    json,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  request( options, (err, _, data) => err ? reject(err) : resolve(data) );

});
